import React from 'react';
import { ChevronRightIcon, CalendarIcon } from '@heroicons/react/solid';
import { UserGroupIcon } from '@heroicons/react/outline';
import { formatDay } from '../../../../utils/helpers';


export default function RegistrationsList({ registrations, openRegistrationView }) {

  const showRegistration = (e, registration) => {
    if (e) {
      e.preventDefault();
    }
    openRegistrationView(registration);
  };

  if (!registrations || !registrations.length) {
    return null;
  }
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {registrations.map((registration) => {
          const { center, product, id, requested_team: requestedTeam } = registration;
          const { start_date: startDate, name, image, team_registrations: teamRegistration } = product;

          const { name: centerName, slug: centerSlug } = center;
          return (
            <li key={id}>
              <a onClick={(e) => showRegistration(e, registration)} href={`/${centerSlug}`} className="block hover:bg-gray-50">
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="flex-shrink-0">
                      <img className="h-12 w-12 rounded-full" src={image} alt={name} />
                    </div>
                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <p className="text-sm font-medium text-indigo-600 truncate">{name}</p>
                        <p className="mt-2 flex items-center text-sm text-gray-500">
                          <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                          <span className="truncate">{formatDay(startDate, 'MMMM Do YYYY')}</span>
                        </p>
                      </div>
                      <div className="hidden md:block">
                        <div>
                          <p className="text-sm text-gray-900">
                            {centerName}
                          </p>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            <UserGroupIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            {!!teamRegistration ? 'Team name' : 'Team name'}: {requestedTeam || '--'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </div>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  )
}
import React, { useRef } from 'react';
import { ClipboardCopyIcon } from '@heroicons/react/outline';
import { copyToClipboard } from '../../../../utils/helpers';
import { useNotifications } from '../../../../contexts/notifications-provider';


/* This example requires Tailwind CSS v2.0+ */
export default function ReferralCode({ url }) {

  const { addNotification } = useNotifications();

  const inputEl = useRef();

  const copy = () => {
    if (copyToClipboard(inputEl)) {
      addNotification({
        type: 'success',
        body: 'Link copied!',
        timeout: 2000,
      });
    }
  };

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Spread The Word and Win Big!</h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p className="mb-3">Every time someone registers for a league with your referral link, you are entered to win prizes! We're giving away bowling balls, bags, birthday parties, Amazon.com gift cards and more!</p>
          <p className="mb-3">It's simple to win!</p>
          <p className="mb-3">Just let your friends and family know that you're signed up for a league and they might enjoy joining you in the FUN!</p>
          <p className="">You can send this link to them via email, send it via text or share it on your favorite social media accounts with all of your friends and family too</p>
        </div>
        <div className="mt-5">
          <div className="rounded-md bg-gray-50 px-6 py-5">
            <h4 className="sr-only">Referral Link</h4>
            <div className="">
              <input ref={inputEl} readOnly value={url} className="bg-gray-50 w-full border-none text-sm p-0 m-0 text-gray-900" type="text" />
            </div>
            <div className="mt-4">
              <button
                type="button"
                onClick={copy}
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              >
                <ClipboardCopyIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Copy
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
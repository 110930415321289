import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import * as Yup from 'yup';
import Container from '../../../components/layout/default/container';
import Loadable from '../../data/loadable';
import RightSlideOver from '../../overlays/right-slide-over';
import { getApiRequest, postApiRequest } from '../../../utils/request';
import { getCurrentUser, setUser, checkClaim } from '../../../utils/auth';
import { formatBirthdayFromServer } from '../../../utils/helpers';
import ReferralCode from './bowler/referral-code';
import AccountDetails from './bowler/account-details';
import AutoForm from '../../form/auto-form';
import UpcomingList from './bowler/upcoming-list';
import RegistrationsList from './bowler/registrations-list';
import RegistrationView from './bowler/registration-view';
import SEO from '../../layout/seo';

function Section({ children, title }) {
  return (
    <section className="mb-8">
      {!!title && (
        <div className="pb-5 mb-5 border-b border-gray-200">
          <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        </div>
      )}
      {children}
    </section>
  );
}

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [sideOpen, setSideOpen] = useState(false);
  const [referralCode, setReferralCode] = useState(null);
  const [showUserForm, setShowUserForm] = useState(false);
  const [registration, setRegistration] = useState(null);
  const [registrations, setRegistrations] = useState(null);
  const [upcoming, setUpcoming] = useState(null);

  useEffect(() => {
    const isAdmin = checkClaim('admin');
    if (isAdmin) {
      navigate('/app/admin/dashboard');
      return;
    }

    const init = async () => {
      setLoading(true);
      try {
        const result = await getApiRequest('/bowler/dashboard');
        const { registrations: regResults, products, ref: refCode } = result || {};
        // console.log(result, 'this is the data for the dashboard');
        setReferralCode(refCode);
        setRegistrations(regResults);
        setUpcoming(products);
      } catch (err) {
        console.log(err, 'should never happen, set an error and tell the user to reload');
      }
      setLoading(false);
    }

    init();
  }, []);

  const openSide = () => setSideOpen(true);
  const closeSide = () => {
    setSideOpen(false);
    setShowUserForm(false);
    setRegistration(null);
  };

  const openRegistrationView = (toView) => {
    openSide();
    setRegistration(toView);
  };

  const openEditForm = () => {
    openSide();
    setShowUserForm(true);
  };;

  const userForm = {
    type: 'post',
    fetchRoute: `/auth/me`,
    fetchErrorMessage: 'There was an error while attempting to load your account.',
    postRoute: `/auth/update-account`,
    successHandler: async () => {
      // Fetch the user...
      setLoading(true);
      closeSide();
      try {
        const newUser = await postApiRequest('/auth/me');
        setUser(newUser);
      } catch (err) {
        // Should never occur
        console.log(err, 'error fetching user');
      }
      setLoading(false);
    },
    successNotification: 'Your account has been updated.',
    fields: [
      {
        name: 'first_name',
        label: 'First name',
        type: 'text',
        required: true,
      },
      {
        name: 'last_name',
        label: 'Last name',
        type: 'text',
        required: true,
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email',
        helpText: 'The email you use to login.',
        required: true,
      },
      {
        name: 'password',
        label: 'Password',
        placeholder: 'Enter a strong password.',
        type: 'password',
        helpText: `Leave the password blank to keep it the same as it is now. If you update it, it must be at least 6 characters.`,
        validations: () => {
          return Yup.string().min(6, 'Must be at least 6 characters.');
        },
        initialValue: () => {
          return '';
        },
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'phone',
        helpText: 'Must be a mobile phone.',
        required: true,
      },
      {
        name: 'birthday',
        label: 'Birthday',
        type: 'date',
        helpText: 'Your birthday.',
        required: true,
        initialValue: (user) => {
          const { birthday_day: day, birthday_month: month, birthday_year: year } = user || {};
          return formatBirthdayFromServer(day, month, year);
        },
      },
    ],
    submitLabel: `Update account`,
  };

  const user = getCurrentUser();
  const { first_name: firstName } = user || {};

  const refCodeUrl = referralCode && `${process.env.GATSBY_SITE_URL}/?r=${referralCode.code}`;

  const sidebarTitle = showUserForm ? 'Update account' : 'Registration details';

  return (
    <Container>
      <SEO title="Dashboard" />
      <div className="py-4 sm:py-8 px-4 sm:px-6 lg:px-8">
        <Loadable loading={loading}>
          <Section>
            <h2 className="text-2xl leading-6 font-medium text-gray-900">Welcome {firstName}!</h2>
          </Section>
          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-full sm:col-span-7 md:col-span-8">
              {!!upcoming && !!upcoming.length && (
                <Section title="Upcoming leagues near you">
                  <UpcomingList products={upcoming} />
                </Section>
              )}
              {!!registrations && !!registrations.length && (
                <Section title="Your leagues">
                  <RegistrationsList registrations={registrations} openRegistrationView={openRegistrationView} />
                </Section>
              )}
            </div>
            <div className="col-span-full sm:col-span-5 md:col-span-4">
              {!!refCodeUrl && <Section><ReferralCode url={refCodeUrl} /></Section>}
              {!!user && <Section><AccountDetails user={user} edit={openEditForm} /></Section>}
            </div>
          </div>
        </Loadable>
      </div>
      <RightSlideOver darkBg large isOpen={sideOpen} close={closeSide} title={sidebarTitle}>
        {sideOpen && showUserForm && <AutoForm noContainer {...userForm} />}
        {sideOpen && !!registration && <RegistrationView registration={registration} user={user} />}
      </RightSlideOver>
    </Container>
  );
}

export default Dashboard;

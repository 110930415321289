import React from 'react';
import { Link } from 'gatsby';
import { ExternalLinkIcon, CalendarIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { formatDay } from '../../../../utils/helpers';
import Money from '../../../data/money';


export default function UpcomingList({ products }) {
  if (!products || !products.length) {
    return null;
  }
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {products.map((product) => {
          const { id, start_date: startDate, name, total_stock: total, purchased, image, price, center, league } = product;
          const { name: centerName } = center;
          const available = total - purchased;
          return (
            <li key={id}>
              <Link to={`/${league.slug}/${center.slug}/${id}`} className="block hover:bg-gray-50">
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="flex-shrink-0">
                      <img className="h-12 w-12 rounded-full" src={image} alt={name} />
                    </div>
                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <p className="text-sm font-medium text-indigo-600 truncate">{name}</p>
                        <p className="mt-2 flex items-center text-sm text-gray-500">
                          <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                          <span className="truncate">Starts {formatDay(startDate, 'MMMM Do YYYY')}</span>
                        </p>
                      </div>
                      <div className="hidden md:block">
                        <div>
                          <p className="text-sm text-gray-900">
                            {centerName}
                          </p>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            
                            {available > 0 && (
                              <>
                                <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
                                {available} spot{available === 1 ? '' : 's'} remaining. 
                                <span className="ml-2 text-gray-900">{' '}<Money value={price} /></span>
                              </>
                            )}
                            {available === 0 && (
                              <>
                                <XCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" aria-hidden="true" />
                                Sold out!
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ExternalLinkIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  )
}
import React from 'react';
import { formatPhoneFromServer, formatDay } from '../../../../utils/helpers';

export default function AccountDetails({ user, edit }) {
  if (!user) {
    return null;
  }

  const { birthday_day: bdayDay, birthday_month: bdayMonth, birthday_year: bdayYear, created_at: created, email, name, phone: phoneRaw } = user;
  const bday = bdayDay ? `${bdayMonth}/${bdayDay}/${bdayYear}` : '--';
  const phone = phoneRaw ? formatPhoneFromServer(phoneRaw) : '';
  const createdDay = formatDay(created, 'M/D/Y');
  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 md:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Account details</h3>
        <div className="mt-5 rounded-md bg-gray-50 px-6 py-5 ">
          <div className="flex items-start justify-between">
            <h4 className="sr-only">Visa</h4>
            <div className="flex items-start">
              <div className="mt-0">
                <div className="text-lg font-medium text-gray-900">{name}</div>
                <div className="text-sm text-gray-800">{email}</div>
                <div className="mt-1 text-sm text-gray-800">{phone}</div>
                
              </div>
            </div>
            <div className="mt-0 ml-2 flex-shrink-0">
              <button
                type="button"
                onClick={edit}
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              >
                Edit
              </button>
            </div>
          </div>
          <div className="mt-2 text-sm text-gray-600 flex items-center">
            <div>Birthday {bday}</div>
            <span className="mx-2 inline" aria-hidden="true">
              &middot;
            </span>
            <div className="mt-0">Joined on {createdDay}</div>
          </div>
        </div>
      </div>
    </div>
  )
}